@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.woff') format('woff'), url('../fonts/Roboto-Medium.woff2') format('woff2'), url('../fonts/Roboto-Medium.eot') format('eot');
    font-weight: 500;
    font-style: normal; }
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.woff') format('woff'), url('../fonts/Roboto-Regular.woff2') format('woff2'), url('../fonts/Roboto-Regular.eot') format('eot');
    font-weight: normal;
    font-style: normal; }
@font-face {
    font-family: 'Prompt';
    src: url('../fonts/Prompt-Regular.woff') format('woff'), url('../fonts/Prompt-Regular.woff2') format('woff2'), url('../fonts/Prompt-Regular.eot') format('eot');
    font-weight: normal;
    font-style: normal; }
@font-face {
    font-family: 'Prompt';
    src: url('../fonts/Prompt-Light.woff') format('woff'), url('../fonts/Prompt-Light.woff2') format('woff2'), url('../fonts/Prompt-Light.eot') format('eot');
    font-weight: 300;
    font-style: normal; }
@font-face {
    font-family: 'Arvo';
    src: url('../fonts/Arvo-Bold.woff') format('woff'), url('../fonts/Arvo-Bold.woff2') format('woff2'), url('../fonts/Arvo-Bold.eot') format('eot');
    font-weight: bold;
    font-style: normal; }
@font-face {
    font-family: 'Arvo';
    src: url('../fonts/Arvo-Regular.woff') format('woff'), url('../fonts/Arvo-Regular.woff2') format('woff2'), url('../fonts/Arvo-Regular.eot') format('eot');
    font-weight: normal;
    font-style: normal; }

* {
    margin: 0;
    padding: 0;
 }    // transition: all .6s ease-in-out
*:focus {
    outline: none; }
body {
    overflow-x: hidden;
    font-family: Prompt;
    font-size: 16px; }
img {
    max-width: 100%;
    vertical-align: middle; }
ul {
    list-style: none; }
a {
    display: inline-block;
    text-decoration: none; }
p {
    margin: 0; }
button {
    cursor: pointer;
    border: none; }

header {
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;
    box-shadow: 0 3px 45px #00000033; }
.header {
    display: flex;
    align-items: center;
    position: relative; }
.header_logos {
    display: flex;
    align-items: center;
    flex: 0 1 25%;
    max-width: 25%; }
.header_logo {
    position: absolute;
    transition: height .5s ease-in-out, transform .5s ease-in-out; }
.header_sub {
    position: absolute;
    left: 156px;
    transition: height .5s ease-in-out, transform .5s ease-in-out; }
.header_sub p {
    position: relative;
    width: 139px;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    letter-spacing: -0.33px;
    font-weight: 500;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px; }
.header_sub span {
    text-align: center;
    position: relative;
    display: block;
    font-family: Arvo;
    font-size: 30px;
    color: #003E84;
    text-transform: uppercase; }
.header_sub:before {
    content: '';
    position: absolute;
    left: 0;
    top: -17px;
    width: 139px;
    height: 153px;
    background-image: url(../img/icon_flag.svg); }
.header_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 1 55%;
    max-width: 55%;
    padding: 0 30px;
    overflow: hidden; }
.header_menu a {
    font-size: 17px;
    color: #000000;
    padding: 49.5px 0;
    position: relative;
    transition: padding .5s ease-in-out .1s; }
.header_menu a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #FFBC21;
    transform: translateY(20px);
    transition: transform .2s ease-in-out; }
.header_menu a:hover:before {
    transform: translateY(0); }
.header_info {
    text-align: right;
    flex: 0 1 20%;
    max-width: 20%;
    position: absolute;
    right: 0;
    transition: height .5s ease-in-out, transform .5s ease-in-out; }
.header_phone a {
    font-size: 35px;
    color: #000000; }
.header_email a {
    font-size: 14px;
    color: #000000;
    position: relative; }
.header_email a:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #FFBC21; }
.header_callback {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    transition: top .5s ease-in-out; }
.header_callback a {
    font-size: 15px;
    color: #fff;
    width: 200px;
    height: 66px;
    background-color: #003E84;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .3s ease-in-out; }
.header_callback a:hover {
    background-color: #0055B5; }
.header_logo__moved {
    transform: translateY(-500px);
    position: absolute;
    width: 72px;
    transition: transform .5s ease-in-out; }

header.moved .header_logo, header.moved .header_sub, header.moved .header_info {
    transform: translateY(-500px); }
header.moved .header_logo__moved {
    transform: translateY(0); }
header.moved .header_callback {
    top: 0; }
header.moved .header_menu a {
    padding: 20px 0; }

.wave, .wave2, .wave3 {
    position: relative;
    overflow: hidden; }
.wave span, .wave2 span, .wave3 span {
    width: 1px;
    height: 1px;
    position: absolute;
    opacity: 0;
    background-color: #ffcf4b;
    border-radius: 50%; }
.wave2 span {
    background-color: #1068cb; }
.wave3 span {
    background-color: #fcfcfc; }
.wave span.active, .wave2 span.active, .wave3 span.active {
    animation: clickEffect .5s; }
@keyframes clickEffect {
    0% {
        transform: scale(0);
        opacity: 1; }
    100% {
        transform: scale(200);
        opacity: 0; } }



.main_slider__item {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
.main_slider__item {
    padding-top: 280px; }
.main_slider__item-title h2 {
    margin-bottom: 10px;
    font-family: Arvo;
    font-size: 30px;
    font-weight: bold;
    line-height: 36px;
    color: #003E84;
    text-transform: uppercase; }
.main_slider__item-title img {
    margin-bottom: 25px; }
.main_slider__item-text {
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 40px; }
.main_slider__item-link a {
    position: relative;
    width: 300px;
    height: 60px;
    background-color: #FFBC21;
    font-size: 17px;
    color: #000;
    display: flex;
    align-items: center;
    padding-left: 30px;
    transition: background-color .3s ease-in-out; }
.main_slider__item-link a:hover {
    background-color: #FFCF4B; }
.main_slider__item-link a:before {
    content: '';
    position: absolute;
    right: -1px;
    top: 0;
    height: 100%;
    width: 55px;
    background-image: url(../img/Subtraction.svg);
    transition: transform .3s ease-in-out; }
.main_slider__item-link a:after {
    content: '';
    position: absolute;
    right: 21px;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    border: 1.5px solid #fff;
    border-right: none!important;
    border-top: none!important;
    transform: rotate(225deg);
    transition: border-color .3s ease-in-out; }
.main_slider__item-link a:hover:after {
    border-color: #000; }
.main_slider__item-link a:hover:before {
    transform: translateX(100%); }

.main_slider .slick-dots {
    display: flex;
    position: absolute;
    height: 70px;
    bottom: 43px;
    left: 50%;
    margin-left: -112.5px;
    background-color: #fff;
    padding: 17px;
    cursor: auto; }
.main_slider .slick-dots li {
    position:relative {}
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 15px;
    overflow: hidden;
    cursor: pointer;
    position: relative; }
.main_slider .slick-dots li:after {
    content: '';
    position: absolute;
    width: 33px;
    height: 33px;
    margin: 1px;
    border-radius: 50%;
    background: white; }
.main_slider .slick-dots li.slick-active:before {
    content: '';
    height: 35px;
    width: 70px;
    background-color: #E1E1E1;
    position: absolute;
    top: -35px;
    left: -16px;
    transform: rotate(0deg);
    transform-origin: 35px 35px;
    transition: all .3s;
    animation: border 10.5s ease-out 1; }
.main_slider .slick-dots .dot {
    width: 12px;
    height: 12px;
    background-color: #E1E1E1;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-left: -6px;
    margin-top: -6px;
    transition: background-color .3s ease-in-out; }
.main_slider .slick-dots li:hover .dot {
    background-color: #B2B2B2; }
.main_slider .slick-dots .slick-active .dot, .main_slider .slick-dots .slick-active:hover .dot {
    background-color: #FFBC21; }
.main_slider .slick-prev {
    position: absolute;
    bottom: 43px;
    left: 50%;
    margin-left: -197px;
    z-index: 2;
    width: 85px;
    height: 70px;
    background-color: #fff; }
.main_slider .slick-next {
    position: absolute;
    bottom: 43px;
    left: 50%;
    margin-left: 116px;
    z-index: 2;
    width: 85px;
    height: 70px;
    background-color: #fff; }
.main_slider .slick-prev:before {
    content: '';
    position: absolute;
    left: 30px;
    top: 50%;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    border: 1.5px solid #E1E1E1;
    border-left: none;
    border-bottom: none;
    transform: rotate(-135deg);
    transition: border-color .3s ease-in-out; }
.main_slider .slick-next:before {
    content: '';
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    border: 1.5px solid #E1E1E1;
    border-left: none;
    border-bottom: none;
    transform: rotate(45deg);
    transition: border-color .3s ease-in-out; }
.main_slider .slick-prev:hover:before, .main_slider .slick-next:hover:before {
    border-color: #878787; }
.main_slider .slick-disabled {
    pointer-events: none; }

.main_points {
    background-color: #F6F6F6;
    padding: 40px 0; }
.main_points__block {
    display: flex;
    align-items: center;
    justify-content: center; }
.main_points__block-age {
    flex: 0 1 33.3%;
    max-width: 33.3%;
    display: flex;
    align-items: center; }
.main_points__block-age_number {
    font-family: Arvo;
    font-weight: bold;
    font-size: 34px;
    color: #003E84;
    text-transform: uppercase; }
.main_points__block-age_text {
    line-height: 22px;
    padding-left: 15px; }
.main_points__block-item {
    line-height: 22px;
    flex: 0 1 33.3%;
    max-width: 33.3%;
    padding-left: 100px;
    position: relative; }
.main_points__block-item:before {
    content: '';
    position: absolute;
    left: 45px;
    top: -4px;
    border: 3px solid #FFBC21;
    width: 20px;
    height: 40px;
    border-bottom: none;
    border-right: none;
    transform: rotate(-495deg); }

h2.title, h1.title {
    font-family: Arvo;
    font-weight: normal;
    font-size: 36px;
    text-align: center; }
.subtitle {
    text-align: center;
    line-height: 24px;
    padding-top: 15px; }

.main_links {
    padding-top: 112px; }
.main_links__block-items {
    display: flex;
    flex-wrap: wrap; }
.main_links__block-items_item {
    flex: 0 1 33.3%;
    max-width: 33.3%;
    border: 1px solid #E2E2E2;
    padding: 45px 55px;
    position: relative; }
.main_links__block-items_item:nth-of-type(1), .main_links__block-items_item:nth-of-type(2) {
    border-bottom: none;
    border-right: none; }
.main_links__block-items_item:nth-of-type(3) {
    border-bottom: none; }
.main_links__block-items_item:nth-of-type(4), .main_links__block-items_item:nth-of-type(5) {
    border-right: none; }
.main_links__block-items_item__title {
    font-family: Arvo;
    font-size: 24px;
    margin-bottom: 30px; }
.main_links__block-items_item a {
    position: relative;
    padding-bottom: 2px;
    margin-bottom: 8px;
    color: #000; }
.main_links__block-items_item a:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: #FFBC21;
    transition: width .3s ease-in-out; }
.main_links__block-items_item a:hover:before {
    width: 100%; }
.main_links__block-items_item img {
    position: absolute;
    right: 13px;
    top: 20px; }

.main_reference {
    padding-bottom: 160px;
    overflow: hidden; }
.main_reference__block {
    position: relative; }
.main_reference__slider {
    padding-top: 60px; }
.main_reference__slider-item {
    height: 480px;
    background-size: cover;
    background-position: center;
    position: relative; }
.main_reference__slider-item_info {
    position: absolute;
    height: 354px;
    width: 335px;
    top: 64px;
    right: 61px;
    background-color: #ffffff;
    box-shadow: 0 3px 16px #00000029;
    padding: 50px 50px 50px 40px; }
.main_reference__slider-item_info__title {
    font-family: Arvo;
    font-size: 22px;
    margin-bottom: 27px; }
.main_reference__slider-item_info p {
    margin-bottom: 20px; }
.main_reference__slider-item_info ul li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 8px; }
.main_reference__slider-item_info ul li:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 2px;
    border: 2px solid #FFBC21;
    width: 8px;
    height: 16px;
    border-bottom: none;
    border-right: none;
    transform: rotate(-495deg); }
.main_reference__block a {
    position: absolute;
    right: 0;
    top: 100%;
    width: 248px;
    height: 60px;
    background-color: #FFBC21;
    color: #000000;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .3s ease-in-out; }
.main_reference__block a:hover {
    background-color: #FFCF4B; }
.main_reference__slider .slick-prev {
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #003E84;
    width: 93px;
    height: 70px;
    z-index: 2;
    margin-left: -93px;
    transition: background-color .3s ease-in-out; }
.main_reference__slider .slick-next {
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #003E84;
    width: 93px;
    height: 70px;
    z-index: 2;
    transition: background-color .3s ease-in-out; }
.main_reference__slider .slick-prev:before {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -8.5px;
    width: 17px;
    height: 17px;
    border: 1.5px solid #ffffff;
    border-right: none;
    border-bottom: none;
    transform: rotate(-45deg);
    transition: right .3s ease-in-out; }
.main_reference__slider .slick-next:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -8.5px;
    width: 17px;
    height: 17px;
    border: 1.5px solid #ffffff;
    border-right: none;
    border-bottom: none;
    transform: rotate(135deg);
    transition: left .3s ease-in-out; }
.main_reference__slider .slick-next:hover, .main_reference__slider .slick-prev:hover {
    background-color: #0055B5; }
.main_reference__slider .slick-next:hover:before {
    left: 35px; }
.main_reference__slider .slick-prev:hover:before {
    right: 35px; }
.main_partners__block {
    background-color: #fafafa;
    margin-top: 50px; }
.main_partners__slider-item {
    background-color: #fafafa; }
.main_partners__slider-item img {
    mix-blend-mode: multiply; }

.main_about {
    // background-image: url(../img/main_about.png)
 }    // background-size: cover
.main_about__item {
    position: relative;
    top: 61px;
    width: 600px;
    padding: 73px 80px 60px;
    background-color: #fff;
    box-shadow: 0 3px 16px #00000029; }
.main_about__item-title {
    font-family: Arvo;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 35px; }
.main_about__item p {
    line-height: 24px;
    margin-bottom: 30px; }
a.substraction {
    position: relative;
    width: 287px;
    height: 60px;
    background-color: #FFBC21;
    font-size: 17px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 55px;
    overflow: hidden;
    transition: background-color .3s ease-in-out; }
a.substraction:before {
    content: '';
    position: absolute;
    right: -1px;
    top: 0;
    height: 100%;
    width: 55px;
    background-image: url(../img/Subtraction.svg);
    transition: transform .3s ease-in-out; }
a.substraction:after {
    content: '';
    position: absolute;
    right: 21px;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    border: 1.5px solid #fff;
    border-right: none;
    border-top: none;
    transform: rotate(225deg);
    transition: border-color .3s ease-in-out; }
a.substraction:hover {
    background-color: #FFCF4B; }
a.substraction:hover:after {
    border-color: #000; }
a.substraction:hover:before {
    transform: translateX(100%); }
.main_about__item a {
    margin: 0 auto;
    margin-top: 55px; }

.main_certs {
    padding: 241px 0 181px; }
.main_certs__block {
    display: flex;
    justify-content: center;
    align-items: center; }
.main_cert_1 {
    position: relative;
    left: 60px;
    transition: transform .4s ease-in-out; }
.main_cert_2 {
    z-index: 1;
    transform: scale(1.2);
    transition: transform .4s ease-in-out; }
.main_cert_2.scaled {
    transform: scale(1); }
.main_cert_3 {
    position: relative;
    right: 60px;
    transition: transform .4s ease-in-out; }
.main_cert_1:hover, .main_cert_2:hover, .main_cert_3:hover {
    z-index: 2;
    transform: scale(1.2); }
.main_cert_1 a, .main_cert_2 a, .main_cert_3 a {
    cursor: zoom-in; }

.main_reviews {
    padding-bottom: 211px; }
.main_reviews__slider {
    padding-top: 55px; }
.main_reviews__slider-item {
    background-color: #F6F6F6;
    padding: 80px 130px 55px;
    display: flex!important; }
.main_reviews__slider-item_left {
    flex: 0 1 20%;
    max-width: 20%;
    text-align: right; }
.main_reviews__slider-item_right {
    flex: 0 1 80%;
    max-width: 80%;
    padding-left: 80px;
    position: relative; }
.main_reviews__slider-item_right:before {
    content: '';
    background-image: url(../img/icon_quotes.svg);
    position: absolute;
    left: 35px;
    top: 5px;
    width: 26.19px;
    height: 18.39px; }
.main_reviews__slider-item_left__dolzh {
    color: #003E84;
    font-family: Arvo;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    padding-left: 40px; }
.main_reviews__slider-item_right__text {
    line-height: 24px;
    margin-bottom: 50px; }
.main_reviews__slider-item_right__link a {
    position: relative;
    color: #000; }
.main_reviews__slider-item_right__link a:before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #FFBC21; }
.main_reviews__slider .slick-prev {
    position: absolute;
    top: 100%;
    left: 50%;
    background-color: #003E84;
    width: 93px;
    height: 70px;
    z-index: 2;
    margin-left: -93px;
    transition: background-color .3s ease-in-out; }
.main_reviews__slider .slick-next {
    position: absolute;
    top: 100%;
    left: 50%;
    background-color: #003E84;
    width: 93px;
    height: 70px;
    z-index: 2;
    transition: background-color .3s ease-in-out; }
.main_reviews__slider .slick-prev:before {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -8.5px;
    width: 17px;
    height: 17px;
    border: 1.5px solid #ffffff;
    border-right: none;
    border-bottom: none;
    transform: rotate(-45deg);
    transition: right .3s ease-in-out; }
.main_reviews__slider .slick-next:before {
    content: '';
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -8.5px;
    width: 17px;
    height: 17px;
    border: 1.5px solid #ffffff;
    border-right: none;
    border-bottom: none;
    transform: rotate(135deg);
    transition: left .3s ease-in-out; }
.main_reviews__slider .slick-next:hover, .main_reviews__slider .slick-prev:hover {
    background-color: #0055B5; }
.main_reviews__slider .slick-next:hover:before {
    left: 35px; }
.main_reviews__slider .slick-prev:hover:before {
    right: 35px; }
.main_useful {
    padding-bottom: 125px; }
.main_useful__block {
    padding-top: 55px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -40px; }
.main_useful .substraction {
    margin: 0 auto; }
.main_useful__block-item {
    padding: 0 40px;
    flex: 0 1 33.3%;
    max-width: 33.3%;
    display: flex;
    flex-direction: column; }
.main_useful__block-item_title a {
    color: #000;
    position: relative;
    padding-top: 37px;
    margin-bottom: 21px;
    font-size: 20px;
    line-height: 30px; }
.main_useful__block-item_title a:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 1px;
    background-color: #ffcf4b;
    transition: width .3s ease-in-out; }
.main_useful__block-item_title a:hover:before {
    width: 100%; }
.main_useful__block-item p {
    font-size: 15px;
    font-family: Roboto;
    line-height: 24px;
    padding-right: 20px;
    margin-bottom: 60px;
    margin-top: auto; }
footer {
    background-color: #003E84;
    overflow: hidden; }
.footer_top a {
    padding: 72px 0 77px;
    position: relative; }
.footer_top a .logo {
    width: 582px;
    height: 395px;
    left: 50%;
    margin-left: -291px;
    top: 0;
    position: absolute;
    pointer-events: none; }
.footer_middle {
    display: flex;
    margin-bottom: 100px; }
.footer_middle__left {
    flex: 1; }
.footer_middle__right {
    flex: 0 1 300px;
    max-width: 300px; }
.footer_middle-title {
    font-size: 20px;
    color: #FFBC21;
    margin-bottom: 30px; }
.footer_middle__nav {
    display: flex; }
.footer_middle__nav a {
    line-height: 36px;
    color: #ffffff;
    font-family: Roboto;
    position: relative; }
.footer_middle__nav a:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 2   px;
    width: 0;
    height: 1px;
    background-color: #ffbc21;
    transition: width .3s ease-in-out; }
.footer_middle__nav a:hover:before {
    width: 100%; }
.footer_middle__nav ul:nth-of-type(2) {
    padding-left: 100px; }
.footer_middle__right address {
    font-family: Roboto;
    color: #fff;
    line-height: 24px;
    margin-bottom: 30px;
    font-style: normal;
    padding-right: 50px; }
a.footer_phone {
    font-weight: 300;
    font-size: 36px;
    color: #FFBC21;
    margin-bottom: 6px; }
a.footer_email {
    color: #ffffff;
    position: relative;
    font-family: Roboto;
    margin-bottom: 50px; }
a.footer_email:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 1px;
    background-color: #ffbc21; }
a.footer_fb {
    position: relative;
    border: 1.3px solid rgba(255, 255, 255, .3);
    color: #fff;
    font-family: Roboto;
    font-size: 15px;
    border-radius: 29px;
    display: flex;
    width: 282px;
    height: 50px;
    align-items: center;
    justify-content: center;
    padding-left: 27px; }
a.footer_fb:before {
    content: '';
    position: absolute;
    left: 22px;
    top: 50%;
    margin-top: -13px;
    background-image: url(../img/icon_fb.svg);
    width: 14px;
    height: 28px;
    background-repeat: no-repeat; }
.footer_bottom {
    display: flex;
    padding-bottom: 50px; }
.footer_bottom__copyright {
    flex: 0 1 386px;
    max-width: 386px;
    color: #5987BB;
    font-family: Roboto;
    font-size: 14px; }
.footer_bottom__link {
    flex: 1; }
.footer_bottom__link a {
    margin-right: 15px;
    color: #5987BB;
    font-family: Roboto;
    font-size: 14px;
    position: relative; }
.footer_bottom__link a:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 0;
    height: 1px;
    background-color: #5987BB;
    transition: width .3s ease-in-out; }
.footer_bottom__link a:hover:before {
    width: 100%; }
.footer_bottom__radian {
    flex: 0 1 300px;
    max-width: 300%; }
.footer_bottom__radian a {
    display: flex;
    align-items: center; }
.footer_bottom__radian a p {
    padding-right: 11px;
    font-size: 13px;
    font-family: Roboto;
    color: #5987BB;
    text-align: right; }

.progress-wrap {
	position: fixed;
	right: 90px;
	bottom: 60px;
	height: 51px;
	width: 51px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	box-shadow: inset  0 0 0 2px #E6E6E6;
	z-index: 20;
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	-webkit-transition: all 200ms linear {
    transition: all 200ms linear; } }
.progress-wrap.active-progress {
	opacity: 1;
	visibility: visible;
	transform: translateY(0); }
.progress-wrap::after {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -4px;
    border: 2px solid #C9C9C9;
    left: 50%;
    margin-left: -8px;
    border-left: none!important;
    border-top: none!important;
    transform: rotate(225deg);
    transition: border-color .25s ease-in-out;
    content: ''; }
.progress-wrap:hover::after {
	border-color: #FFBC21; }
.progress-wrap svg path {
	fill: none; }
.progress-wrap svg.progress-circle path {
	stroke: #FFBC21;
	stroke-width: 4;
	box-sizing:border-box {}
	-webkit-transition: all 200ms linear {
    transition: all 200ms linear; } }

.my-mfp-zoom-in .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8); }
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; }
.my-mfp-zoom-in.mfp-bg {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out; }
.my-mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0;
    background-color: rgba(0,0,0,0); }
.my-mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0; }
.my-mfp-slide-bottom .zoom-anim-dialog {
    opacity: 0;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    -webkit-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
    -moz-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
    -ms-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
    -o-transform: translateY(-20px) perspective( 600px ) rotateX( 10deg );
    transform: translateY(-20px) perspective( 600px ) rotateX( 10deg ); }

.my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
    opacity: 1;
    -webkit-transform: translateY(0) perspective( 600px ) rotateX( 0 );
    -moz-transform: translateY(0) perspective( 600px ) rotateX( 0 );
    -ms-transform: translateY(0) perspective( 600px ) rotateX( 0 );
    -o-transform: translateY(0) perspective( 600px ) rotateX( 0 );
    transform: translateY(0) perspective( 600px ) rotateX( 0 ); }

.my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
    opacity: 0;
    -webkit-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
    -moz-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
    -ms-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
    -o-transform: translateY(-10px) perspective( 600px ) rotateX( 10deg );
    transform: translateY(-10px) perspective( 600px ) rotateX( 10deg ); }

.my-mfp-slide-bottom.mfp-bg {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out; }
.my-mfp-slide-bottom.mfp-ready.mfp-bg {
    opacity: 0.8; }
.my-mfp-slide-bottom.mfp-removing.mfp-bg {
    opacity: 0; }

.form-popup {
    position: relative;
    max-width: 749px;
    background-color: #fff;
    margin: 0 auto;
    padding: 50px 70px 60px;
    box-shadow: 0 3px 45px #00000029;
    transform: translateX(0);
    transition: transform .3s ease-in-out; }
.form-popup.active {
    transform: translateX(-24%); }
.form-popup .mfp-close {
    font-size: 0;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 12px;
    opacity: 1; }
.form-popup .mfp-close:before {
    content: '';
    position: absolute;
    left: 3px;
    top: 11px;
    width: 20px;
    height: 1.5px;
    background-color: #003E84;
    transform: rotate(45deg);
    transition: background-color .3s ease-in-out; }
.form-popup .mfp-close:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 11px;
    width: 20px;
    height: 1.5px;
    background-color: #003E84;
    transform: rotate(-45deg);
    transition: background-color .3s ease-in-out; }
.form-popup .mfp-close:hover:before, .form-popup .mfp-close:hover:after {
    background-color: #FFBC21; }

.form-title {
    font-family: Arvo;
    font-size: 28px;
    text-align: center;
    margin-bottom: 50px; }
.form-popup label.input_label {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 35px; }
.form-popup label.input_label input {
    width: 100%;
    padding: 15px 0 15px 205px;
    border: none;
    border-bottom: 1px solid #D2D2D2;
    font-size: 18px;
    font-family: Roboto;
    color: #003E84; }
.form-popup label.input_label p {
    position: absolute;
    font-family: Roboto;
    top: 50%;
    margin-top: -10px; }
.form-popup label.input_label.label_choose input {
    cursor: pointer;
    padding-right: 50px; }
.form-popup label.input_label.label_choose:before {
    content: '';
    position: absolute;
    right: 5px;
    top: 15px;
    width: 11px;
    height: 11px;
    border: 1.5px solid #003E84;
    transform: rotate(225deg);
    border-bottom: none;
    border-right: none;
    transition: border-color .3s ease-in-out, transform .3s ease-in-out; }
.form-popup label.input_label.label_choose.active:before {
    transform: rotate(135deg);
    border-color: #FFBC21; }
.form-popup label.input_label.label_choose.toomuch:after {
    content: '...';
    position: absolute;
    right: 30px;
    bottom: 15px;
    font-size: 18px;
    font-family: Roboto;
    color: #003e84; }
.form-popup .tonext {
    background-color: #003E84;
    color: #ffffff;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 60px;
    margin: 60px auto 0;
    transition: background-color .3s ease-in-out; }
.form-popup .tonext:hover {
    background-color: #0055B5; }

.interior {
    position: absolute;
    left: 101%;
    top: 0;
    width: 415px;
    background-color: #ffffff;
    box-shadow: 0 3px 16px #00000029;
    padding: 37px 37px 26px 40px;
    transform: translateX(1000%);
    transition: transform .3s ease-in-out; }
.interior.active {
    transform: translateX(0); }
.interior_title {
    font-size: 18px;
    margin-bottom: 5px; }
.interior input {
    display: none; }
.interior label {
    position: relative;
    font-family: Roboto;
    margin-bottom: 10px;
    padding-left: 34px;
    display: block;
    cursor: pointer; }
.interior label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ABABAB; }
.interior label:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 18px;
    top: -5px;
    left: 9px;
    border-right: 2px solid #FFBC21;
    border-bottom: 2px solid #FFBC21;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity .2s ease-in-out; }
.interior input[type=checkbox]:checked+label:after {
    opacity: 1; }
.interior_ok {
    width: 150px;
    height: 44px;
    margin: 35px auto 0;
    background-color: #003E84;
    color: #ffffff;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color .3s ease-in-out; }
.interior_ok:hover {
    background-color: #0055B5; }

.cookie {
    position: fixed;
    bottom: 20px;
    right: 20px;
    max-width: 325px;
    transform: translateX(1000px);
    transition: transform .3s ease-in-out;
    padding: 20px 25px 35px;
    background-color: #fff;
    z-index: 30;
    box-shadow: 0 3px 26px #00000029; }
.cookie.on {
    transform: translateX(0); }
.cookie_title {
    text-align: center;
    font-size: 15px;
    margin-bottom: 23px; }
.cookie_block input {
    display: none; }
.cookie_block label {
    font-size: 12px;
    line-height: 16px;
    font-family: Roboto;
    position: relative;
    padding-left: 28px;
    display: block;
    padding-right: 15px; }
.cookie_block label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ABABAB; }
.cookie_block label:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 18px;
    top: -5px;
    left: 9px;
    border-right: 2px solid #FFBC21;
    border-bottom: 2px solid #FFBC21;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity .2s ease-in-out; }
.cookie_block input[type=checkbox]:checked+label:after {
    opacity: 1; }
.cookie_agree {
    width: 220px;
    height: 44px;
    font-size: 15px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #003E84;
    cursor: pointer;
    margin: 16px auto 0;
    transition: background-color .3s ease-in-out, opacity .3s ease-in-out; }
.cookie_agree:hover {
    background-color: #0055B5; }
.cookie_agree.active {
    pointer-events: none;
    opacity: .4; }

body.loading {
    overflow: hidden; }
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .3s ease-in-out; }
// .preloader.loaded
//     transform: translateY(-100%)
.page_padding {
    padding-top: 200px; }

.page_text {
    font-size: 15px;
    font-family: Roboto;
    line-height: 24px;
    margin-top: 20px;
    text-align: center; }

.breadcrumbs_block {
    margin-bottom: 30px; }

.breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center; }

.breadcrumbs__item {
    margin: 0 18px; }

.breadcrumbs__link {
    color: #000;
    position: relative; }

.breadcrumbs__link:before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #FFBC21; }

.main_slider .slick-dots li.slick-active:before {
    content: '';
    height: 35px;
    width: 70px;
    background-color: #e1e1e1;
    position: absolute;
    top: -35px;
    left: -16px;
    transform: rotate(0deg);
    transform-origin: 35px 35px;
    transition: all .3s;
    animation: border 10.5s ease-out 1; }

@keyframes border {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(180deg); } }


.tabs {
    overflow: hidden;
    padding-bottom: 120px; }

.tabs__content {
    height: 0;
    transform: translateY(200%);
    overflow: hidden;
    transition: all .3s ease-in-out; }

.tabs__content.active {
    height: auto;
    transform: translateY(0);
    overflow: visible; }

.tabs__caption {
    margin: 55px 0 40px;
    display: flex;
    justify-content: center;
    align-items: center; }

.tabs__caption li {
    cursor: pointer;
    height: 70px;
    width: 265px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 3px 16px #00000029;
    transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out, color .3s ease-in-out; }

.tabs__caption li.active {
    box-shadow: none;
    background-color: #003E84;
    color: #fff; }

.main_links__textblock {
    border: 1px solid #e2e2e2;
    padding: 60px 255px 124px;
    text-align: center; }

.main_links__textblock img {
    margin-bottom: 40px; }

.main_links__textblock p {
    font-family: Roboto;
    line-height: 24px;
    margin-bottom: 25px; }

.main_links__textblock a.substraction {
    margin: 80px auto 0; }

.header_submenu {
    position: absolute;
    left: 0;
    top: 100%;
    padding-top: 75px;
    transform: translateX(500%);
    transition: padding-top .3s ease-in-out, transform .3s ease-in-out;
    pointer-events: none; }

header.moved .header_submenu {
    padding-top: 33px; }

.header_submenu.active {
    transform: translateX(0);
    pointer-events: auto; }

.submenu_block {
    background-color: #fff;
    display: flex;
    box-shadow: 0 3px 45px #00000029; }

.submenu_block__left {
    flex: 0 1 851px;
    max-width: 851px;
    display: flex;
    padding: 63px 50px 0 55px; }

.submenu_block__right {
    border-left: 1px solid #D2D2D2;
    flex: 0 1 388px;
    max-width: 388px;
    padding: 69px 65px 78px 50px; }

.submenu_block__left-left {
    margin-right: 55px; }

.submenu_block__left-item {
    padding-left: 55px;
    margin-bottom: 50px;
    position: relative; }

.submenu_block__left-item img {
    width: 45px;
    height: 45px;
    position: absolute;
    left: 0;
    top: -10px; }

.submenu_block__left-item_title {
    font-family: Arvo;
    font-size: 20px;
    margin-bottom: 15px; }
.submenu_block__left-item_title a {
    color: #000; }
.submenu_block__left-item p {
    line-height: 32px; }

.submenu_block__left-item p a {
    color: #000;
    position: relative; }

.submenu_block__left-item p a:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 2px;
    width: 0;
    height: 1px;
    background-color: #ffbc21;
    transition: width .3s ease-in-out; }

.submenu_block__left-item p a:hover:before {
    width: 100%; }

.submenu_block__right-title {
    margin-bottom: 33px;
    font-family: Arvo;
    font-size: 20px;
    display: flex;
    align-items: center;
    line-height: 30px; }

.submenu_block__right-title img {
    width: 45px;
    height: 45px;
    margin-right: 10px; }

.submenu_block__right p {
    font-family: Roboto;
    margin-bottom: 30px;
    line-height: 24px; }

.submenu_block__right a {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #003E84;
    color: #fff;
    font-size: 17px;
    width: 220px;
    height: 60px;
    transition: background-color .3s ease-in-out; }

.submenu_block__right a:hover {
    background-color: #0055B5; }

.storitve_subcat {
    margin: 0 -40px;
    display: flex;
    flex-wrap: wrap;
    padding: 90px 0; }

.storitve_subcat__item {
    padding: 0 40px 40px;
    flex: 0 1 33.3%;
    max-width: 33.3%; }

.storitve_subcat__item img {
    margin-bottom: 20px; }

.storitve_subcat__item a {
    color: #000;
    font-size: 20px; }

.storitve_subcat__item a:hover {
    text-decoration: underline; }

.storitve_page {
    padding: 50px 0 100px;
    display: flex; }

.storitve_page__left {
    flex: 0 1 530px;
    max-width: 530px; }

.storitve_page__right {
    flex: 1;
    padding-left: 55px; }

.storitve_page__right p {
    font-size: 15px;
    font-family: Roboto;
    margin-bottom: 20px;
    line-height: 24px; }

.storitve_page__right p:last-of-type {
    margin-bottom: 45px; }

.uscl-list .uscl-item {
    padding-top: 0!important;
    padding-bottom: 0!important; }

.uscl-list .uscl-item:first-of-type {
    padding-left: 0!important;
    padding-right: 13px!important; }

.reference_links {
    padding: 60px 55px 115px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

.reference_item p {
    position: relative;
    margin-bottom: 10px;
    line-height: 30px;
    padding-left: 30px; }

.reference_item p:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 4px;
    border: 2px solid #ffbc21;
    width: 8px;
    height: 16px;
    border-bottom: none;
    border-right: none;
    transform: rotate(-495deg); }

.main_useful__category .main_useful__block-item p {
    margin-bottom: 80px; }

.page_article {
    display: flex;
    padding-top: 50px;
    padding-bottom: 30px; }

.page_article__left {
    flex: 0 1 530px;
    max-width: 530px; }

.page_article__right {
    flex: 1;
    padding-left: 55px; }

.page_article__right p {
    font-size: 15px;
    font-family: Roboto;
    margin-bottom: 20px;
    line-height: 24px; }

.page_article__right p:last-of-type {
    margin-bottom: 45px; }

.main_useful__block-more {
    padding-bottom: 30px; }

.page_404 {
    padding-top: 50px;
    padding-bottom: 125px;
    text-align: center; }

.page_404 img {
    margin-bottom: 45px; }

.page_404 .title_404 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px; }

.page_404 p {
    font-family: Roboto;
    font-size: 15px;
    margin-bottom: 50px; }

.page_404 a {
    background-color: #003E84;
    color: #fff;
    width: 220px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    margin: 0 auto;
    transition: background-color .3s ease-in-out; }

.page_404 a:hover {
    background-color: #0055B5; }

.map {
    position: relative;
    top: 7px; }

.contact {
    padding: 50px 0 120px 206px;
    display: flex; }

.contact_block {
    display: flex; }

.contact_title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 21px; }

.contact_left {
    flex: 0 1 275px;
    max-width: 275px; }

.contact p {
    font-size: 15px;
    font-family: Roboto;
    line-height: 24px;
    margin-bottom: 14px; }

.contact_block__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 15px; }

.contact_block a {
    font-weight: 300;
    font-size: 34px;
    color: #000; }

.contact_block__left {
    padding-right: 60px; }

.map_title {
    font-size: 20px;
    margin-bottom: 25px;
    text-align: center; }

.header_burger {
    display: none; }

body.opened {
    overflow: hidden; }

.mobile_header {
    display: none; }

.main_certs__slider {
    display: none; }

.main_slider .slick-dots:before {
    content: '';
    position: absolute;
    left: -85px;
    top: 0;
    width: calc(100% + 170px);
    height: 100%;
    box-shadow: 0 3px 16px #00000029; }
.cookie_block label a {
    color: #000;
    text-decoration: underline; }

.cookie_block label a:hover {
    text-decoration: none; }

#agree {
    display: none; }

.agree {
    position: relative; }

.agree label {
    line-height: 22px;
    font-size: 18px;
    padding-left: 34px;
    display: block; }

.agree label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    border: 1px solid #ababab; }

.agree label:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 18px;
    top: -3px;
    left: 9px;
    border-right: 2px solid #ffbc21;
    border-bottom: 2px solid #ffbc21;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: 0;
    -webkit-transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out; }

.agree input[type=checkbox]:checked+label:after {
    opacity: 1; }
h1.title {
    font-family: Arvo;
    font-weight: normal;
    font-size: 36px;
    text-align: center; }

.big_form {
    max-width: 900px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 80px; }

.big_form label.input_label {
    position: relative;
    margin-bottom: 35px;
    display: flex;
    align-items: center; }

.big_form label.input_label input {
    width: 100%;
    padding: 15px 0 15px 252px;
    border: 0;
    border-bottom: 1px solid #d2d2d2;
    font-size: 18px;
    font-family: Roboto;
    color: #003e84;
    font-weight: 500; }

.big_form label.input_label textarea {
    width: 100%;
    padding: 15px 0 15px 252px;
    border: 0;
    border-bottom: 1px solid #d2d2d2;
    font-size: 18px;
    font-family: Roboto;
    color: #003e84;
    font-weight: 500;
    resize: none;
    height: 85px; }

.big_form label.input_label p {
    position: absolute;
    font-size: 18px;
    top: 50%;
    margin-top: -15px; }

.big_form label.input_label p.fortextarea {
    margin-top: -30px; }

button.substraction {
    position: relative;
    width: 287px;
    height: 60px;
    background-color: #FFBC21;
    font-size: 17px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 55px;
    overflow: hidden;
    transition: background-color .3s ease-in-out;
    font-family: Prompt;
    font-size: 16px; }

button.substraction:before {
    content: '';
    position: absolute;
    right: -1px;
    top: 0;
    height: 100%;
    width: 55px;
    background-image: url(../img/Subtraction.svg);
    transition: transform .3s ease-in-out; }

button.substraction:after {
    content: '';
    position: absolute;
    right: 21px;
    top: 50%;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    border: 1.5px solid #fff;
    border-right: none;
    border-top: none;
    transform: rotate(225deg);
    transition: border-color .3s ease-in-out; }

button.substraction:hover {
    background-color: #FFCF4B; }

button.substraction:hover:after {
    border-color: #000; }

button.substraction:hover:before {
    transform: translateX(100%); }

.radio_block {
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap; }

.radio_block__left {
    flex: 0 1 230px;
    max-width: 230px;
    font-size: 18px; }

.radio_block__right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px; }

.radio_block__item {
    flex: 0 1 33.3%;
    max-width: 33.3%; }

.radio_block__item input[type=radio] {
    display: none; }

.radio_block__item label {
    position: relative;
    display: block;
    padding-left: 34px;
    cursor: pointer;
    margin-bottom: 10px; }

.radio_block__item label:before {
    border: 1px solid #ababab;
    left: 0;
    top: 3px;
    width: 18px;
    content: '';
    position: absolute;
    height: 18px;
    border-radius: 100%; }

.radio_block__item label:after {
    top: 7px;
    left: 4px;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    content: '';
    position: absolute;
    height: 10px;
    background-color: #ffbc21;
    width: 10px;
    border-radius: 100%; }

.radio_block__item input[type=radio]:checked+label:after {
    opacity: 1; }

.radio_block__item input[type=text] {
    max-width: 100%;
    border: none;
    border-bottom: 1px solid #D2D2D2;
    margin-left: 34px;
    padding-bottom: 5px;
    font-size: 15px;
    font-family: Roboto;
    color: #003e84; }

.check_block {
    padding-top: 15px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap; }

.check_block__left {
    font-size: 18px;
    flex: 0 1 230px;
    max-width: 230px; }

.check_block__right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px; }

.check_block__right-left {
    flex: 0 1 50%;
    max-width: 50%; }

.check_block__right-right {
    flex: 0 1 50%;
    max-width: 50%; }

.check_block__item {
    margin-bottom: 25px; }

.check_title {
    margin-bottom: 10px;
    font-size: Roboto; }

.check_block input[type=checkbox] {
    display: none; }

.check_block label {
    position: relative;
    display: block;
    font-family: Roboto;
    padding-left: 34px;
    cursor: pointer;
    margin-bottom: 10px; }

.check_block label:before {
    border: 1px solid #ababab;
    left: 3px;
    top: 0;
    width: 18px;
    content: '';
    position: absolute;
    height: 18px; }

.check_block label:after {
    width: 7px;
    top: -5px;
    left: 12px;
    border-right: 2px solid #ffbc21;
    border-bottom: 2px solid #ffbc21;
    transform: rotate(45deg);
    opacity: 0;
    transition: opacity .2s ease-in-out;
    content: '';
    position: absolute;
    height: 18px; }

.check_block input[type=checkbox]:checked+label:after {
    opacity: 1; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

input[type=number] {
    -moz-appearance: textfield; }

.big_form__last {
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }

.big_form__last p {
    flex: 1;
    font-size: 14px;
    font-family: Roboto;
    line-height: 18px;
    padding-right: 35px; }
.mobile_arrow {
    display: none; }
.big_form__last p a {
    color: #000;
    text-decoration: underline; }
.big_form__last p a:hover {
    text-decoration: none; }
